<template>
    <v-row style="padding-left: 30px;padding-right: 30px;" class=" pb-7">
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>
        </div>
        <v-col cols="12"
               :lg="column"
               md="6"
               sm="12"
               v-for="(item, index) in offers"
               :key="index">

            <v-card class="offers-theme"
                    style="padding: 15px;min-height: 112px;; cursor: pointer;box-shadow: none !important; border-radius: 10px;"
                    >
                <div class="d-flex flex-column">
                    <v-row>

                        <v-col @click="getDetail(item)"
                               cols="12"
                               lg="3"
                               md="12"
                               style="padding-top: 12px;">
                            <div style="background: #F3F4F9;height:60px;width: 60px;border-radius: 10px;margin: auto;">
                                <img :src="item.image"
                                     style="margin-top: 15px;width: 46px;height: 32px;margin-left: 7px;margin-right: 7px;">
                            </div>
                        </v-col>
                        <v-col cols="12"
                               lg="7"
                               md="12"
                               @click="getDetail(item)">
                            <div class="text-sm"
                                 style="color:#8A8A8A;font-size:12px !important;">
                                {{ item.merchants[0].merchantName }}
                            </div>
                            <div class="text-black font-weight-semibold"
                                 style="font-size: 14px;"
                                 v-html="item.shortDescription">
                            </div>
                            <div class="text-sm"
                                 v-if="!item.isSaved"
                                 style="color:#8A8A8A;font-size:13px !important;">
                                {{ $t('Expires') }} {{ getArabicDate(item.offerEndDate, selectedLanguage) }}
                            </div>
                            <div class="text-sm"
                                 v-if="item.isSaved"
                                 style="color:#8A8A8A;font-size:13px !important;">
                                {{ $t('Expires') }} {{ getArabicDate(item.expiryDate, selectedLanguage) }}
                            </div>
                        </v-col>

                        <v-col cols="12"
                               lg="2"
                               md="12"
                               class="text-center">
                            <img src="@/assets/images/logos/offer-plus.png"
                                 @click="getDetail(item)"
                                 v-if="!item.isSaved"
                                 alt=""
                                 style="margin-top: 28px;width: 24px;">
                            <img src="@/assets/images/logos/Success-Filled.png"
                                 alt=""
                                 v-if="item.isSaved"
                                 style="margin-top: 28px;width: 24px;">
                        </v-col>
                    </v-row>
                </div>
            </v-card>



        </v-col>
        <v-dialog v-model="dialog"
                  @input="handleDialogInput"
                  style="overflow: hidden;">
            <v-card class="offer-details-dialog offer-detail-width">
                <v-card-title>
                    <span class="text-h5 text-black font-weight-semibold"
                          style="margin-top: -14px;font-size: 1rem !important;">{{ $t('Offer information') }}</span>
                    <span class="dilog-cross"
                          @click="dialog = false">
                        <v-icon>
                            {{ icons.mdiClose }}
                        </v-icon>
                    </span>
                </v-card-title>
                <div style="overflow-y: auto;"
                     class="offers-popup-data"
                     v-if="dialog"
                     ref="dialogRef">
                    <div class="mx-4">
                        <v-card class="pa-7 mt-4">
                            <div class="d-flex align-center">
                                <img style="width:50%;border-radius: 9px;margin: auto;"
                                     :src="`https://online.americanexpress.com.sa/smartphoneapi/OffersV1/OfferImages/${offerDetail.offerImage}`">

                                <!-- <v-divider class="my-16"
                                           :vertical="true"></v-divider> -->
                                <!-- <div class="d-flex flex-column align-center"
                                     style="width: 100%;">
                                    <div class="text-black font-weight-semibold mt-2 text-2xl">
                                        {{ offerDetail.merchants[0].merchantName }}
                                    </div>
                                </div> -->
                            </div>
                        </v-card>
                    </div>
                    <div class="mx-4 mt-4">
                        <v-card class="pa-7">
                            <div class="text-black font-weight-semibold"
                                 style="font-size: 14px;text-shadow: 0px 0px;"
                                 v-html="offerDetail.shortDescription">
                            </div>

                            <div class="text-1xl font-weight-semibold"
                                 style="font-size: 13px;color: #006FCF;text-shadow: 0px 0px;">
                                {{ $t('Expires') }} {{ getArabicDate(offerDetail.endDate, selectedLanguage) }}
                            </div>
                        </v-card>
                    </div>
                    <!-- <div class="mx-4">
                        <div class="text-black font-weight-semibold mt-5"
                             style="font-size: 13px;">
                            {{ $t('Offer information') }}
                        </div>
                    </div> -->
                    <div class="mx-4 mt-4">
                        <v-card class="pa-7">
                            <div class="text-black font-weight-semibold"
                                 style="font-size: 12px;text-shadow: 0px 0px;">
                                {{ $t('Offer details') }}
                            </div>
                            <div class="text-1xl font-weight-semibold mt-2"
                                 id="longDescription"
                                 style="font-size: 11px;text-shadow: 0px 0px;"
                                 v-html="offerDetail.longDescription">
                            </div>
                        </v-card>
                    </div>
                    <div class="mx-4 mt-4 mb-4">
                        <v-card class="pa-7">
                            <div class="text-black font-weight-semibold mb-2"
                                 style="font-size: 12px;text-shadow: 0px 0px;">
                                {{ $t('Terms & Conditions') }}
                            </div>
                            <div class="text-1xl font-weight-semibold"
                                 style="font-size: 11px;text-shadow: 0px 0px;"
                                 v-html="offerDetail.termsAndCondition">
                            </div>
                        </v-card>
                    </div>
                    <div class="mx-4 mt-4 mb-4">
                        <v-card class="pa-7">
                            <div class="text-black font-weight-semibold mb-5"
                                 style="font-size: 12px;text-shadow: 0px 0px;">
                                {{ $t('Eligible cards') }}
                            </div>
                            <div class="text-1xl font-weight-semibold"
                                 style="font-size: 11px;text-shadow: 0px 0px;">

                                <v-row v-if="!this.item.isSaved">
                                    <v-col cols="12"
                                           lg="6"
                                           md="5"
                                           sm="12"
                                           v-for="(card, idx) in cards"
                                           :key="idx"
                                           v-if="card.isActive">
                                        <v-card class="mt-1 mb-1 pb-3 px-3">
                                            <v-row>
                                                <v-col cols="12"
                                                       lg="4"
                                                       class="">
                                                    <v-img contain
                                                           class="auth-3d-group overflow-hidden"
                                                           style="height: 33px;"
                                                           :src="card.image">
                                                    </v-img>
                                                </v-col>
                                                <v-col cols="12"
                                                       lg="8"
                                                       md="12"
                                                       sm="12"
                                                       class="pa-0 supp-card-text">
                                                    <div>
                                                        <div style="font-size: 10px;"
                                                             class="text-black font-weight-bold">
                                                            {{ card.cardMemberName }}
                                                        </div>
                                                        <div style="font-size: 9px;">
                                                            {{ $t('Card ending -') }} {{ card.Last4Digits }}
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row v-if="this.item.isSaved">
                                    <v-col cols="12"
                                           lg="6"
                                           md="5"
                                           sm="12"
                                           v-for="(card, idx) in cards"
                                           :key="idx">
                                        <v-card class="mt-1 mb-1 pb-3 px-3">
                                            <v-row>
                                                <v-col cols="12"
                                                       lg="4"
                                                       class="">
                                                    <v-img contain
                                                           class="auth-3d-group overflow-hidden"
                                                           style="height: 33px;"
                                                           :src="card.image">
                                                    </v-img>
                                                </v-col>
                                                <v-col cols="12"
                                                       lg="8"
                                                       md="12"
                                                       sm="12"
                                                       class="pa-0 supp-card-text">
                                                    <div>
                                                        <div style="font-size: 10px;"
                                                             class="text-black font-weight-bold">
                                                            {{ card.cardMemberName }}
                                                        </div>
                                                        <div style="font-size: 9px;">
                                                            {{ $t('Card ending -') }} {{ card.Last4Digits }}
                                                        </div>
                                                    </div>

                                                </v-col>

                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>

                            </div>
                        </v-card>
                    </div>
                    <div class="mx-4 mb-8 d-flex justify-center"
                         v-if="item && !item.isSaved">
                        <v-btn type="button"
                               color="primary"
                               @click="saveOffer(item)"
                               class="mx-auto">
                            {{ $t('savetoCard') }}
                        </v-btn>
                    </div>
                </div>
                <br>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>


import store from '@/store';
import offerServices from '@/services/offers-api-services';
import restErrorMessages from '@/@core/utils/rest-error-messages';
import { mdiClose } from '@mdi/js';
import sharedServices from '@/services/shared-services';
import SupplimentaryCard from '../card-details/SupplimentaryCard.vue';
import alert from '../alert.vue';
import arabicDate from '@/@core/utils/arabicDate';
import moment from 'moment'

export default {

    components: { alert, SupplimentaryCard },
    props: {
        offers: {
            default: 'American Express Card',
            type: []
        },
        column: {
            default: '4',
            type: String
        },
    },
    setup()
    {
        return {
            icons: {
                mdiClose
            },
        }
    },
    data: () => ({
        dialog: false,
        cards: [],
        offerDetail: {
            offerImage: '',
            merchants: [{ merchantName: '' }],
            shortDescription: '',
            endDate: '',
            longDescription: '',
            termsAndCondition: ''
        },
        item: null,
        showAlert: false,
        selectedLanguage: 'en',
    }),
    created() 
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        };
        this.cards = store.get('cardStore/allCards')
    },
    methods: {
        getArabicDate(dateTime, lang)
        {
            return arabicDate.get(dateTime, lang, true)
        },
        async getDetail(item)
        {
            store.dispatch('loader/show');
            await sharedServices.getToken('entity', store.get('requestKeys/userName')).then(tokenRes =>
            {
                store.set('requestKeys/channelKey', tokenRes.data.results.channelKey);
                store.set('requestKeys/identityKey', tokenRes.data.results.identityKey);
                store.set('requestKeys/requestDate', tokenRes.data.results.requestDate);
            });
            this.item = item;
            let data = {
                requestInfo: {
                    requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                    requestDate: store.get('requestKeys/requestDate'),
                    channelKey: store.get('requestKeys/channelKey'),
                    identityKey: store.get('requestKeys/identityKey'),
                    channelId: store.get('requestKeys/channelId'),
                    identitySeed: store.get('requestKeys/identitySeed'),
                    userName: store.get('requestKeys/userName'),
                },
                clientCode: store.get('requestKeys/ClientCode'),
                offerSerno: item.offerSerno,
                language: localStorage.getItem('language') == 'en' ? 'E' : 'A',
            }
            offerServices.getOfferdetail(data).then(res =>
            {
                this.offerDetail = res?.data?.offerDetails;
                if (this.offerDetail.endDate)
                {
                    let dates = this.offerDetail.endDate.split('/');
                    let newDate = moment(dates[1] + ' ' + dates[0] + ' ' + dates[2]).format('DD/MMM/YYYY');
                    this.offerDetail.endDate = newDate;
                }

                this.dialog = true;
                return;
            })
        },
        handleDialogInput(value)
        {
            this.dialog = true
            if (value)
            {
                this.$nextTick(() =>
                {
                    const dialogContent = this.$refs.dialogRef.$el.querySelector('.v-dialog__content');
                    if (dialogContent)
                    {
                        dialogContent.scrollTop = 0;
                    }
                });
            }
        },
        saveOffer(item)
        {
            let accountsToSave = store.get('cardStore/activeCards');
            let accountsNo = []
            accountsToSave.forEach(element =>
            {
                accountsNo.push(element.accountNo)
            });
            let data = {
                requestInfo: {
                    requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                    requestDate: store.get('requestKeys/requestDate'),
                    channelKey: store.get('requestKeys/channelKey'),
                    identityKey: store.get('requestKeys/identityKey'),
                    channelId: store.get('requestKeys/channelId'),
                    identitySeed: store.get('requestKeys/identitySeed'),
                    userName: store.get('requestKeys/userName'),
                },

                offerSerno: item.offerSerno,
                accountsNo: accountsNo
            }
            offerServices.GetSaveoffer(data).then(res =>
            {
                // console.log(res);
                if (res.data?.responseInfo?.errorCode == "00000")
                {
                    this.alertMessage = 'offerDetailsPageOfferLinkedtoCard';
                    this.alertType = 'success'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false;
                    }, 5000);

                    item.isSaved = true;
                    this.dialog = false;
                }
                else
                {
                    this.alertMessage = restErrorMessages.get('offer', 'something_went_wrong');
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000);
                }
            })
        }

    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-app-bar-title__conten {
    bottom: 115px !important;
}

.offer-detail-width {
    width: 60%;
    // height: 400px;
    // overflow: hidden;
    margin: auto;
}

.offers-popup-data {
    height: 70vh !important;
}

@media screen and (max-width: 809px) {
    .offer-detail-width {
        width: 98%;
        // height: 95vh;
        // overflow: hidden;
    }
}

.terms {
    li {
        font-size: 10px;
    }
}

.dilog-cross {
    position: absolute;
    right: 10px;
    top: 13px;
    cursor: pointer;
}

.icon-detail {
    position: relative;
    top: -123px;
}

.icon-image {
    background: #F3F4F9;
    padding: 12px 7px 4px;
    border-radius: 10px;
    margin-left: 33px;
    text-shadow: 1px 1px black;
}

@media screen and (max-width: 600px) {
    // .offers-popup-data {
    //     height: auto !important;
    // }
}
</style>

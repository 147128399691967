<template>
    <v-card class="overflow-hidden mobile-banner-height"
            style="border-radius: 0px !important">
        <v-img :src="responsiveHeaderImage"
               class="mobile-banner-height">
            <div class="d-flex justify-space-between">
                <v-row class="justify-space-between">
                    <v-col cols="12"
                           lg="7"
                           md="6"
                           sm="12">
                        <div class="d-flex align-center flex-row">
                            <v-btn @click="onGoHome()"
                                   color="white"
                                   class="ar-navicon d-lg-flex d-none mt-lg-7 mt-md-5 md-sm-4 mt-2 ml-lg-7 ml-md-7 ml-sm-5 ml-3"
                                   icon>
                                <v-icon size="21"
                                        style="color: #fff; cursor: pointer;">
                                    $vuetify.icons.home
                                </v-icon>
                            </v-btn>
                            <div class="d-lg-flex d-none mx-2 mt-lg-8 mt-md-5 md-sm-4 mt-2"
                                 style="width: 1px;background: white; height: 21px;"></div>

                                 <span v-if="sharedState.isCenturion">
                        <v-tooltip color="primary"
                                   top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs"
                                       v-on="on"
                                       @click="onGoCenturion()"
                                       color="white"
                                       class="d-lg-flex d-none mt-lg-7 mt-md-5 md-sm-4 mt-2"
                                       icon>
                                    <v-icon size="21"
                                            style="color: #fff; cursor: pointer;">
                                        $vuetify.icons.card
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="max-width: 200px;padding: 6px;">
                                <span>{{ $t(`Centurion`) }}</span>
                            </div>
                        </v-tooltip>
                    </span>


                    <div v-if="sharedState.isCenturion" class="d-lg-flex d-none mx-2 mt-lg-8 mt-md-5 md-sm-4 mt-2"
                         style="width: 1px;background: white; height: 21px;">
                    </div>

                            <div class="text-center">
                                <v-menu v-model="menu"
                                        :close-on-content-click="false"
                                        :nudge-width="200"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="white"
                                               v-bind="attrs"
                                               v-on="on"
                                               class="mt-lg-8 mt-md-5 md-sm-4 mt-2"
                                               icon>
                                            <v-icon size="25"
                                                    style="color: #fff; cursor: pointer;">
                                                $vuetify.icons.menu
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card class="pa-1">
                                        <v-card-text class="pa-6"
                                                     style="max-width: 570px;">
                                            <v-row>
                                                <v-col cols="12"
                                                       lg="6"
                                                       md="6"
                                                       sm="12"
                                                       v-for="menu in headerMenus"
                                                       class="pa-2">
                                                    <div class="d-flex align-center"
                                                         style="cursor: pointer;"
                                                         @click="manageCard(menu.route)">
                                                        <div>
                                                            <img class="mx-2"
                                                                 :src="menu.image"
                                                                 style="max-width: 22px;max-height: 22px;margin-top: 5px;">
                                                        </div>
                                                        <div style="width: 100%;"
                                                             class="font-weight-bold mx-2">{{ $t(menu.name) }}</div>
                                                    </div>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </div>
                            <!-- <div class="text-center">
                                <v-menu v-model="menu"
                                        :close-on-content-click="false"
                                        :nudge-width="200"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-app-bar-nav-icon class="ar-navicon mt-8 ml-lg-7 ml-md-7 ml-sm-5 ml-3"
                                                            style="color: white"
                                                            v-bind="attrs"
                                                            v-on="on">
                                        </v-app-bar-nav-icon>
                                    </template>
                                    <v-card class="pa-1">
                                        <v-card-text class="pa-6"
                                                     style="max-width: 570px;">
                                            <v-row>
                                                <v-col cols="12"
                                                       lg="6"
                                                       md="6"
                                                       sm="12"
                                                       v-for="menu in headerMenus"
                                                       class="pa-2">
                                                    <div class="d-flex align-center"
                                                         style="cursor: pointer;"
                                                         @click="manageCard(menu.route)">
                                                        <div>
                                                            <img class="mx-2"
                                                                 :src="menu.image"
                                                                 style="max-width: 22px;max-height: 22px;margin-top: 5px;">
                                                        </div>
                                                        <div style="width: 100%;"
                                                             class="font-weight-bold mx-2">{{ $t(menu.name) }}</div>
                                                    </div>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </div> -->
                            <div class="mt-8 header-title ml-2 mr-2">
                                <!-- <div class="font-weight-light offer-title-banner"
                                     style="color: white;">
                                    {{ $t('Amex Offers') }}
                                </div> -->
                                <div class="font-weight-semibold text-1xl offer-title-banner"
                                     style="color: white;font-size: 15px;">
                                    {{ $t('appHeaderOffers') }}
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="10"
                           lg="4"
                           md="6"
                           sm="12"
                           class="top-header">
                        <v-card class="offers-theme"
                                style="padding: 12px 0px;width: fit-content;">
                            <v-dialog v-model="dialog"
                                      class="dialoge-width">
                                <template v-slot:activator="{ on, attrs }">
                                    <ul
                                        :class="{ 'offers-tab': selectedLanguage == 'en', 'offers': selectedLanguage == 'ar' }">
                                        <li @click="savedOffers"
                                            class="saved-off">
                                            <span>
                                                <img src="@/assets/images/logos/saved-offers.png"
                                                     alt="">
                                            </span>
                                            <span :class="{ 'mr-3': selectedLanguage == 'ar' }"
                                                  class="text-color-black">
                                                {{ isSavedOffers ? $t('Available Offers') : $t('Saved Offers') }}
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <img src="@/assets/images/offers/bulb.png"
                                                     alt="">
                                            </span>
                                            <span :class="{ 'mr-3': selectedLanguage == 'ar' }"
                                                  class="text-color-black"
                                                  v-bind="attrs"
                                                  v-on="on">
                                                <!-- {{ $t('How it works') }} -->
                                                {{ $t('howItWorks') }}
                                            </span>
                                        </li>
                                    </ul>
                                </template>
                                <v-card>
                                    <div class="mx-8 pt-6">
                                        <div class="text-h5 text-color-black"
                                             style="font-size: 19px !important;font-weight: 300;margin-top: -14px;">{{
                                                 $t('howItWorks') }}
                                        </div>
                                        <span class="dilog-cross"
                                              @click="dialog = false">
                                            <v-icon @click="closeDialoge()">
                                                {{ icons.mdiClose }}
                                            </v-icon>
                                        </span>
                                    </div>
                                    <v-card-text>
                                        <div>
                                            <v-timeline :reverse="reverse"
                                                        dense
                                                        class="mt-5">
                                                <v-timeline-item color="#27C8B2">
                                                    <div class="text-h5 text-color-black"
                                                         style="font-size: 18px !important;font-weight: 300;">{{
                                                             $t('howitworkExplore') }}</div>
                                                    {{ $t(`howItWorksStep1desc`) }}


                                                </v-timeline-item>
                                                <v-timeline-item color="#27C8B2">
                                                    <div class="text-h5 text-color-black"
                                                         style="font-size: 18px !important;font-weight: 300;">
                                                        {{ $t('howItWorksStep2Title') }}
                                                    </div>

                                                    {{ $t(`howItWorksStep2desc`) }}

                                                </v-timeline-item>
                                                <v-timeline-item color="#27C8B2">
                                                    <div class="text-h5 text-color-black"
                                                         style="font-size: 18px !important;font-weight: 300;">
                                                        {{ $t('howItWorksStep3Title') }}</div>

                                                    {{ $t(`howItWorksStep3desc`) }}
                                                </v-timeline-item>
                                            </v-timeline>
                                        </div>
                                    </v-card-text>

                                </v-card>
                            </v-dialog>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-img>
    </v-card>
</template>

<script>


import
{
    mdiClose
} from '@mdi/js'

import router from '@/router'
import store from '@/store';

import header from './header';
import mobileHeader from './mobileHeader';

import { sharedState } from '@/services/sharedState.js'

export default {
    props: {
        selectedLanguage: {
            default: 'en',
            type: String
        },

        title: {
            default: 'Saved Offers',
            type: String
        },
        isSavedOffers: {
            default: false,
            type: Boolean
        },
    },
    setup()
    {
        return {

            icons: {

                mdiClose
            },
        }
    },
    data: () => ({
        fav: true,
        reverse: false,
        menu: false,
        dialog: false,
        message: false,
        hints: true,
        headerMenus: [
            {
                name: 'Manage Cards',
                image: require('@/assets/images/Managecards.png'),
                route: '/manage-card-details',
            },
            {
                name: 'Trip Plan',
                image: require('@/assets/images/Calendar.png'),
                route: '/trips',
            },
            {
                name: 'Transactions and Statements',
                image: require('@/assets/images/icons/transaction-statement.png'),
                route: '/view-transaction',
            },
            {
                name: 'FAQs',
                image: require('@/assets/images/login/faqs.png'),
                route: '/faqs',
            },
            {
                name: 'Amex Offers',
                image: require('@/assets/images/icons/amex-offer.png'),
                route: '/offers',
            },
            {
                name: 'Membership Rewards',
                image: require('@/assets/images/Membershiprewards.png'),
                route: '/membership-rewards',
            },
            {
                name: 'Alerts & Notifications',
                image: require('@/assets/images/notifications.png'),
                route: '/alert-notifications',
            },
            {
                name: 'Add Supplementary Cards',
                image: require('@/assets/images/AddSupplemntry.png'),
                route: '/apply-supplementary',
            },
            {
                name: 'Contact Us',
                image: require('@/assets/images/login/contact-us.png'),
                route: '/contact-us',
            },
            // {
            //     name: 'Centurion',
            //     image: require('@/assets/images/AddSupplemntry.png'),
            //     route: '/centurion-home',
            // },
        ],
        headerImage: header.headerImage,
        mobileHeaderImage: mobileHeader.mobileHeaderImage,
        responsiveHeaderImage: header.headerImage,
    }),
    computed: {
    sharedState() {
      return sharedState
    }
  },
    created()
    {
        this.details = store.get('card/details');

        window.addEventListener("resize", this.myEventHandler);

        if (screen.width < 1000)
        {
            console.log('screen', screen.width);
            this.responsiveHeaderImage = this.mobileHeaderImage;
        }
        else
        {
            console.log('screen', screen.width);
            this.responsiveHeaderImage = this.headerImage;
        }
    },
    destroyed()
    {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        onGoHome()
        {
            router.push('/home');
        },
        myEventHandler(e)
        {
            // console.log(e);
            if (screen.width < 1000)
            {
                console.log('screen', screen.width);
                this.responsiveHeaderImage = this.mobileHeaderImage;
            }
            else
            {
                console.log('screen', screen.width);
                this.responsiveHeaderImage = this.headerImage;
            }
        },
        savedOffers()
        {
            if (this.isSavedOffers)
            {
                this.$emit('details', false)
            }
            else
            {
                this.$emit('details', true)
            }
        },
        closeDialoge()
        {
            this.dialog = false
        },
        manageCard(route)
        {
            if (route == '/membership-rewards')
            {
                const routeData = router.resolve(route);
                console.log(routeData);
                window.open(routeData.href, '_blank');
                // window.open('/#/membership-rewards');
                // this.PostObjectToUri()
            }
            else
            {
                router.push(route);
                window.scrollTo(0, 0);
                store.set('manageCard/details', null)
            }
            // router.push(route);
            // window.scrollTo(0, 0);
            // store.set('manageCard/details', null)
        },
        suspendCard()
        {
            if (this.showDialog == false)
            {
                this.showDialog = true;
            } else
            {
                this.showDialog = false
            }
            store.set('manageCard/details', null)
        },
        switchIcon()
        {
            router.push('/block-card/primary');
            store.set('manageCard/details', null)
        },
        close()
        {
            this.showDialog = false;
            store.set('manageCard/details', null)
        },
        viewPin()
        {
            router.push('/view-pin/primary');
            store.set('manageCard/details', null)
        },
        activateCard()
        {
            router.push('/active-card/primary');
            store.set('manageCard/details', null)
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.offers-tab {

    li {
        list-style-type: none;
        display: inline-block;
        padding-right: 18px !important;
        padding-left: 17px !important;
        border-right: 1px solid #D0D0D0;
        font-size: 13px;
    }

    li:last-child {
        border-right: none;
    }

    img {
        padding-right: 7px;
        margin-bottom: -3px;
    }
}

.offers {

    li {
        list-style-type: none;
        display: inline-block;
        padding-right: 9px !important;
        padding-left: 19px !important;
        border-left: 1px solid #D0D0D0;
        font-size: 13px;
    }

    li:last-child {
        border-left: none;
    }

    img {
        padding-right: 7px;
        margin-bottom: -3px;
    }
}

.mobile-banner-height {
    max-height: 200px;
}

.offer-title-banner {
    margin-left: 5px;
}

@media screen and (max-width: 1000px) {
    .offer-title-banner {
        margin-left: 20px;
        width: 80%;
    }
}

.offers-theme {
    margin-top: 20px;
}

@media screen and (max-width: 960px) {

    .top-header {
        margin: -43px 20px !important;
    }

    .mobile-banner-height {
        height: 350px;
        max-height: 350px;
    }

    .offers-theme {
        margin-top: 120px;
    }
}

@media screen and (max-width: 431px) {

    .ml-10 {
        margin-left: 0px !important;
    }

    .mr-10 {
        margin-right: 0px !important;
    }

    .header-title {
        margin-left: -25px;
        margin-right: -25px;
    }

    .offers-tab li[data-v-0d89da7c] {

        margin-bottom: 6px !important;
    }

}

::v-deep .v-timeline-item__divider {
    align-items: self-start !important;
}

::v-deep.v-timeline {
    padding-top: 0px !important;

}

::v-deep .v-timeline-item__inner-dot primary {
    background-color: #27C8B2 !important;
    border-color: #27C8B2 !important;
}

::v-deep .v-application .primary {
    background-color: #27C8B2 !important;
    border-color: #27C8B2 !important;
}

.v-timeline-item__inner-dot {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: aquamarine !important;
}

.v-timeline::before {
    background: #27C8B2 !important;
}

::v-deep .v-timeline-item__dot .v-timeline-item__inner-dot {
    height: 24px !important;
    margin: 1px !important;
    width: 24px !important;
}

::v-deep .v-timeline-item__dot {
    border: 2px solid #27C8B2 !important;
    width: 30px !important;
    height: 30px !important;
}

.dilog-cross {
    position: absolute;
    right: 10px;
    top: 13px;
    cursor: pointer;
}

.v-timeline::before {
    background: #27C8B2 !important;
    height: 83%;
}

@media screen and (max-width: 680px) {

    .v-timeline::before {
        background: #27C8B2 !important;
        height: 74%;
    }


}

::v-deep .v-dialog {

    width: 50% !important;
}

@media screen and (max-width: 1151px) {
    .offers-tab li {
        border-right: 0px solid #D0D0D0;
        border-bottom: 1px solid #D0D0D0;
        padding-top: 8px;
        padding-bottom: 11px;
        width: 100%;
    }

    .offers li {
        border-right: 0px solid #D0D0D0;
        border-bottom: 1px solid #D0D0D0;
        padding-top: 8px;
        padding-bottom: 11px;
        width: 100%;
    }

    .offers-tab {
        li:last-child {
            border-left: none !important;
            border-bottom: none !important;
        }
    }

    .offers {
        li:last-child {
            border-left: none !important;
            border-bottom: none !important;
        }
    }

}

@media screen and (max-width: 600px) {

    ::v-deep .v-dialog {

        width: 100% !important;
    }
}
</style>
